import React from 'react';

export default function NotFound(){
    return(
        <div className="my-auto text-center align-middle d-flex-block align-content-center h-100">
            <h1>404!</h1>
            <h2>Page Not Found!</h2>
            
        </div>
    )
}